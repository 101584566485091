<template>
  <div>
    <!-- <xml version="1.0" encoding="UTF-8"> -->
    <!-- <svg
      :width="width"
      :height="height"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      @click="toggleActivity"
    >  -->
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <!-- <title>D194E7AD-C00A-42AE-837C-A5293A0773D3</title> -->
    <!-- <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="Spec"
          transform="translate(-714.000000, -651.000000)"
          :fill="liked?likedColor:noColor"
          fill-rule="nonzero"
        >
          <g id="dislike" transform="translate(713.000000, 650.208094)">
            <path
              d="M12.9291778,11.3889401 L12.9291778,1.68387097 L15.8406985,1.68387097 C16.3485048,1.68387097 16.7651885,2.07388112 16.8076433,2.57064416 L16.8112054,2.65437788 L16.8112054,10.4184332 C16.8112054,10.9262395 16.4212484,11.3429231 15.9244406,11.3853779 L15.8406985,11.3889401 L12.9291778,11.3889401 Z M7.46676629,15.1105303 C7.36103151,15.2278752 7.20704268,15.2849927 7.05400615,15.268232 L6.98862083,15.2564858 L6.27590482,15.0783066 C5.83282183,14.967893 5.4745689,14.664135 5.29307247,14.2452254 C5.12670109,13.8616564 5.13194957,13.4358814 5.30480394,13.0606101 L5.35609854,12.9595852 L6.36688149,11.1394092 C6.48819515,10.9204764 6.40289632,10.7295025 6.36072101,10.6579465 C6.32323183,10.5939198 6.21984443,10.4546343 6.02126966,10.4244724 L5.94228501,10.418637 L3.07531927,10.4186489 C2.08727821,10.4186489 1.28310357,9.61447719 1.28310357,8.62643322 C1.28310357,8.53608032 1.29005382,8.44572699 1.3036735,8.35635667 L1.32906998,8.22316139 L2.36496965,3.46919151 C2.6419552,2.45750736 3.54469314,1.7393631 4.58443729,1.687555 L4.70498798,1.68455547 L9.53240582,1.68408157 C10.8296336,1.68408157 11.8924744,2.70761249 11.9556978,3.98944378 L11.9586731,4.11034885 L11.9586731,9.18844863 C11.9586731,9.74222883 11.7705118,10.2758242 11.4258705,10.7061136 L11.3364685,10.8114856 L7.46676629,15.1105303 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>  -->
    <!-- <svg
      id="Component_8_1"
      data-name="Component 8 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      @click="toggleActivity"
    >
      <g id="Path_28" data-name="Path 28" :fill="liked?likedColor:noColor">
        <path
          d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
          stroke="none"
        />
        <path
          d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
          stroke="none"
          fill="#79797b"
        />
      </g>
      <g
        id="thumb_up_black_24dp_1_"
        data-name="thumb_up_black_24dp (1)"
        transform="translate(22 22) rotate(180)"
      >
        <path id="Path_17" data-name="Path 17" d="M0,0H17V17H0Z" fill="none" />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M1,14H3.727V6.2H1ZM16,6.85a1.337,1.337,0,0,0-1.364-1.3h-4.3l.648-2.97L11,2.371a.956.956,0,0,0-.3-.689L9.98,1,5.493,5.283a1.242,1.242,0,0,0-.4.917v6.5A1.337,1.337,0,0,0,6.455,14h6.136a1.357,1.357,0,0,0,1.255-.793L15.9,8.625A1.232,1.232,0,0,0,16,8.15Z"
          fill="white"
        />
      </g>
    </svg>  -->
    <svg
      id="Component_8_1"
      data-name="Component 8 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      :class="'Component_8_1_dislike' + content.objectid"
      viewBox="0 0 27 27"
       @click="toggleActivity"
    >
      <g id="Path_28" data-name="Path 28" :fill="liked?likedColor:noColor">
        <path
          d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
          stroke="none"
        />
        <path
          d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
          stroke="none"
          fill="#79797b"
        />
      </g>
      <g
        id="thumb_up_black_24dp_1_"
        data-name="thumb_up_black_24dp (1)"
        transform="translate(22 22) rotate(180)"
      >
        <path id="Path_17" data-name="Path 17" d="M0,0H17V17H0Z" fill="none" />
        <path
          id="Path_18"
          :class="'dislikeClass' + content.objectid"
          data-name="Path 18"
          d="M1,14H3.727V6.2H1ZM16,6.85a1.337,1.337,0,0,0-1.364-1.3h-4.3l.648-2.97L11,2.371a.956.956,0,0,0-.3-.689L9.98,1,5.493,5.283a1.242,1.242,0,0,0-.4.917v6.5A1.337,1.337,0,0,0,6.455,14h6.136a1.357,1.357,0,0,0,1.255-.793L15.9,8.625A1.232,1.232,0,0,0,16,8.15Z"
          :fill="liked?thumbSelectedColor:thumbColor"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import { _projectName } from "@/provider-config.js";

export default {
  props: {
    content: {
      type: Object,
    },
    dislikeFireCount: {
      type: Number,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  data() {
    return {
      action: null,
      liked: false,
      likedColor: null,
      // likedColor: "#ffffff", //nammaflix color
      thumbSelectedColor:"#ffffff",
      noColor: "#161616",
      likeBlackColor: "black",
      thumbColor:"#848486",
      currentState: null,
      dislikeCount: 0,
      dislikeCountDisplay: null,
      contentLikeCount: null,
      applyColor: "clr-red",
      defaultColor: "dislike",
    };
  },
  watch: {
    dislikeCountDisplay(val) {
      // console.log("dislikeCountDisplay", val);
      this.$emit("dislikeCount", val);
      // eventBus.$emit("dislike-count-to-mixin", val);
    },
    dislikeFireCount(val) {
      console.log("content from parent", val);
    },
    content() {
      this.fetchButtonState();
      this.getContentDislikeCount();
      this.getContentLikeCount();
    },
    subscriberid() {
      if (this.subscriberid) {
        this.fetchButtonState();
      }
    },
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },
  mounted() {
    // this.getContentDislikeCount();
    // this.getContentLikeCount();

    try {
        document.getElementsByClassName(`Component_8_1_dislike${this.content.objectid}`)[0].addEventListener("mouseover", () => {
          document.getElementsByClassName(`dislikeClass${this.content.objectid}`)[0].setAttribute("fill", "white")
        });

        document.getElementsByClassName(`Component_8_1_dislike${this.content.objectid}`)[0].addEventListener("mouseout", () => {
          if (!this.liked)
          document.getElementsByClassName(`dislikeClass${this.content.objectid}`)[0].setAttribute("fill", "#848486")
        })

    } catch (e) {
      
    }

  },
  created() {
    this.fetchButtonState();
    eventBus.$on("updatelikeDislikeStatus", () => {
      // this.updateLikeDislikeToFire(this.content, "DISLIKE");
    });
    this.likedColor =
      _projectName === "VLIVE"
        ? "#FF9600"
        : _projectName === "NET5"
        ? "#FF9600"
        : _projectName === "NAMMAFLIX"
        ? "#ffffff"
        : _projectName === "Noor Play"
        ? "#ec1c65"
        : "";
    // eventBus.$on("decrease-dislike-count", () => {
    //   eventBus.$emit("dislike-count-to-mixin", this.dislikeCountDisplay);
    //   this.dislikeCountDisplay =
    //     this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
    //   // console.log("decrease-dislike-count", this.dislikeCountDisplay);
    //   this.$emit("dislikeCount", this.dislikeCountDisplay);
    // });
  },
  methods: {
    fetchButtonState() {
      if (!this.content) return;

      let content = this.content;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }

      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          if (response && response.likestatus === "DISLIKE") {
            this.liked = true;
            this.action = "REMOVE";
            this.currentState = "";
          } else if (response && response.likestatus === "LIKE") {
            this.action = "ADD";
            this.liked = false;
            this.currentState = "LIKE";
          } else {
            this.liked = false;
            this.action = "ADD";
            this.currentState = "";
          }
          // console.log("action", this.action);
        });
    },
    getContentDislikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "dislike",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.dislikeCountDisplay = result;
        } else {
          this.dislikeCountDisplay = 0;
        }
        // console.log("dislikeCount", this.dislikeCountDisplay);
        // this.$emit("dislikeCount", this.dislikeCountDisplay);
        // eventBus.$emit("dislike-count-to-mixin", this.dislikeCountDisplay);
      });
    },
    getContentLikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "like",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.contentLikeCount = result;
        } else {
          this.contentLikeCount = 0;
        }
        // console.log("like count", this.contentLikeCount);
        // this.$emit("likeCount", this.contentLikeCount);
        // eventBus.$emit("like-count-to-mixin", this.contentLikeCount);
      });
    },
    toggleActivity() {
      if (this.subscriberid) {
        // this.$emit("updateDislikeCount", this.action);
        if (this.action === "ADD" && this.currentState === "") {
          // console.log("add");
          // this.updateContentCount(
          //   this.content,
          //   this.dislikeCountDisplay,
          //   "DISLIKE"
          // );
          this.updateLikeDislikeToFire(this.content, "DISLIKE");

          //calculate counts
          this.dislikeCountDisplay = this.dislikeCountDisplay + 1;
          this.$emit("dislikeCount", this.dislikeCountDisplay);
        } else if (this.action === "ADD" && this.currentState === "LIKE") {
          // when the content is LIKE change the status and count
          console.log("contentLikeCount", this.contentLikeCount);
          this.updateContentCount(
            this.content,
            this.dislikeCountDisplay,
            "DISLIKE",
            this.contentLikeCount
          );
          this.updateLikeDislikeToFire(this.content, "DISLIKE");
          this.dislikeCountDisplay = this.dislikeCountDisplay + 1;
          // this.contentLikeCount =
          //   this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
          this.$emit("dislikeCount", this.dislikeCountDisplay);
          this.$emit("likeCount", this.contentLikeCount);
        } else if (this.action === "REMOVE") {
          // console.log("remove");
          this.updateContentCount(
            this.content,
            this.dislikeCountDisplay,
            "REMOVEDISLIKE"
          );
          this.updateLikeDislikeToFire(this.content, "NONE");

          //calculate counts
          this.dislikeCountDisplay =
            this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
          this.$emit("dislikeCount", this.dislikeCountDisplay);
        }
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        console.log("payload", payload);
        eventBus.$emit("authPopup", payload);
      }
    },
  },
  mixins: [FirebaseActions],
};
</script>

<style>

@media only screen and (min-width: 800px) {
   #Component_8_1:hover g { 
    fill: #848486;
 }
}

</style>

